import logo1 from '../../logoLea.jpg'
// import twitter from '../../twitter.svg'
// import opensea from '../../opensea.svg'


function Header(){
    return(
        <header className=" flex flex-col items-center pt-8 md:justify-center md:flex-row">
            <img alt="Logotype" src={logo1} width={275} height={66}/>
            {/* <div className="flex social-media justify-between items-center w-[150px] my-4 absolute right-0 top-10">
                <a href="https://opensea.io/collection/doodled-punks" rel="noreferrer" target="_blank">
                    <img src={twitter} alt="Twitter" width={40} height={40}/>
                </a>
                <a href="https://opensea.io/collection/doodled-punks" rel="noreferrer" target="_blank">
                    <img src={opensea} alt="Opensea" width={40} height={40}/>
                </a>
            </div> */}
        </header>
    )
}


export default Header;

