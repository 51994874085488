import './App.css';
import Header from './components/header/Header';
import Main from './components/main/Main';
import Footer from './components/footer/Footer';
import { Notifications } from './components/notifications/Notifications';


function App() {

  return (
    <div>
      <div style={{ "position": "fixed", "xIndex": "9999", "top": "16px", "bottom": "16px", "right": "16px", "left": "16px", "pointerEvents": "none" }}></div>
      <div className='container mx-auto max-w-[1140px] px-4'>
        <Header />
        <Main />
        <Footer />
        <Notifications/>
      </div>
    </div>
  );
}

export default App;

