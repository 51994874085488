// import { useState } from "react"
// import { NotificationList } from "../notifications/Notifications";


function Footer() {

    // let [showOne, setShowOne] = useState(false)
    // let [showTwo, setShowTwo] = useState(false)
    // let [showThree, setShowThree] = useState(false)
    // let [showFour, setShowFour] = useState(false)
    // let [showFive, setShowFive] = useState(false)



    return (
        <footer className="w-full my-14" style={{ "height": "500px" }}>
            {/* <div className="roadmap">
                <div className="data">
                    <h1 className="mb-8 rdtext">ROADMAP</h1>
                    <div className="text">
                        <div className="bold rdtext-roadmap">Staking</div>
                        Stake your Doodled Punk to earn a toke
                        <br />
                        Tokens will be used in the future for an array of utilities
                        <br />
                        <br />
                        <div className="bold rdtext-roadmap">Airdrops</div>
                        Exclusive artwork related to the world of Doodled Punks, airdropped straight to our holders wallets.
                        <br />
                        <br />
                        <div className="bold rdtext-roadmap">Breeding</div>
                        We do not want to give out too many details, as we do not want to be front run on this unique breeding mechanic.
                        <br />
                        This is only the beginning...
                        <br />
                        <br />
                        <div className="bold rdtext-roadmap">Metaverse</div>
                        We plan on developing, building, and expanding the Doodled Punks community to thrive within the Metaverse.
                        <br />
                        <br />
                        <div className="bold rdtext-roadmap">Meetups</div>
                        We will organize and sponsor IRL meetups to further our communities growth, connection, and integrity.
                        <br />
                        <br />
                    </div>
                    <div className="my-12">
                        <h1 className="rdtext">FAQ</h1>
                        <div className="accordion">
                            <div className="faqone">
                                <div className="accordion-item" onClick={() => { if (showOne) { setShowOne(showOne = false) } else { setShowOne(showOne = true) } }}>
                                    <div className="accordion-title">
                                        <div>What is an NFT?</div>
                                        <div>{(showOne) ? "-" : "+"}</div>
                                    </div>
                                    {showOne && <div className="bg-white accordion-content">These tokens are unique and cannot be replaced for something else. They are one of a kind digital item that users can buy, own, and trade. Some NFTs only purpose is to exist as artwork, others offer additional utility like exclusive access to gated sections of websites &amp; Discord servers, or participation in an event. We like to think of NFTs as a collectable piece of art that doubles as a “ members only” access key.</div>}
                                </div>
                            </div>
                            <div className="faqtwo">
                                <div className="accordion-item" onClick={() => { if (showTwo) { setShowTwo(showTwo = false) } else { setShowTwo(showTwo = true) } }}>
                                    <div className="accordion-title">
                                        <div>What is Metamask?</div>
                                        <div>{(showTwo) ? "-" : "+"}</div>
                                    </div>
                                    {showTwo && <div className="bg-white accordion-content">Metamask is a web3 cryptocurrency wallet where you can store Ethereum. It is required to purchase and mint your NFT. Having a wallet gives you a unique Ethereum address (i.e. 0x1234…ABCD), this is where your NFT will be sent upon successful purchase.

                                        Metamask also doubles as a gate function.

                                        Learn more about Metamask and how easy it is to use here: https://metamask.io/
                                    </div>}
                                </div>
                            </div>
                            <div className="faqthree">
                                <div className="accordion-item" onClick={() => { if (showThree) { setShowThree(showThree = false) } else { setShowThree(showThree = true) } }}>
                                    <div className="accordion-title">
                                        <div>Where will my Doodled Punk go?</div>
                                        <div>{(showThree) ? "-" : "+"}</div>
                                    </div>
                                    {showThree && <div className="bg-white accordion-content">
                                        Your Doodled Punks will be sent to the wallet you connected / purchased from. It will be viewable on our website and official OpenSea collection.
                                    </div>}
                                </div>
                            </div>
                            <div className="faqfour">
                                <div className="accordion-item" onClick={() => { if (showFour) { setShowFour(showFour = false) } else { setShowFour(showFour = true) } }}>
                                    <div className="accordion-title">
                                        <div>How much is a Doodled Punk?</div>
                                        <div>{(showFour) ? "-" : "+"}</div>
                                    </div>
                                    {showFour && <div className="bg-white accordion-content">0.1 ETH + gas</div>}
                                </div>
                            </div>
                            <div className="faqfive">
                                <div className="accordion-item" onClick={() => { if (showFive) { setShowFive(showFive = false) } else { setShowFive(showFive = true) } }}>
                                    <div className="accordion-title">
                                        <div>What can I do with my Doodled Punk?</div>
                                        <div>{(showFive) ? "-" : "+"}</div>
                                    </div>
                                   {showFive &&  <div className="bg-white accordion-content">To the extent possible under law, we have waived all copyright and related or neighboring rights to Doodled Punks. This work is published from: United States.</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <h1 className="rdtext">Our Contracts:</h1>
                    <div className="flex flex-wrap justify-center my-6 text">
                        <a href="https://etherscan.io/address/0xf21d1b31b15282592ff0e48f7b474b57ae418361#code" rel="noreferrer" target="_blank" className="m-4 underline hover:no-underline">Verified NFT Smart Contract</a>
                        <a href="https://etherscan.io/address/0x55ddc9cac7afd54223caefe79d0fb36af6ecb4d0#code" rel="noreferrer" target="_blank" className="m-4 underline hover:no-underline">Verified Staking Smart Contract</a>
                        <a href="https://etherscan.io/address/0x00e595d4060dcf65c9c5622aa02d6b999f7835db#code" rel="noreferrer" target="_blank" className="m-4 underline hover:no-underline">Verified Coin Smart Contract</a>
                    </div>
                    <div className="flex flex-wrap justify-center my-6 text">Doodled Punks is marked with CCO 1.0</div>
                </div>
            </div> */}
        </footer>
    )
}


export default Footer;