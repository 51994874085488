import { shortenAddress, useNotifications } from '@usedapp/core'


export function Notifications() {
    const { notifications } = useNotifications()
    console.log("notifications", notifications)

    return (
        <>
            {notifications && notifications.length > 0 && <div style={{ fontFamily: "RobotoMedium" }} className='p-2 m-4 fixed bottom-2 right-2 bg-gray-300 rounded-[10px]'>
                {notifications.map((notification) => {
                    return (
                        <div key={notification.id}>
                            <span className='text-zinc-700'>{notification.type}</span>
                            {notification.address && <p>Address: {shortenAddress(notification.address)}</p>}
                        </div>
                    )
                })}
            </div>}
        </>
    )
}
