import { useTransactions } from "@usedapp/core"


export const Transactions = () => {
    
    const { transactions } = useTransactions()

    return (
        <div className="flex justify-center w-full flex-col">
            {transactions.map((t, index) => {
                return (
                    <div className="text-[15px] w-full border-b" key={index}>
                        <div className="flex ml-3">
                            <div className="w-2/3">Transaction: <a href={`https://etherscan.io/tx/${t.transaction.hash}`}>{t.transaction.hash.substring(0, 7)}</a></div>
                            <div>{(t.receipt === undefined) ? "Mining" : t.receipt.status ? <span className="text-green-800">&#10004; Success</span> : <span className="text-red-800">&#10006; Fail</span>}</div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}