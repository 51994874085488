import { useState, useEffect } from 'react'
import vid from '../../video.mp4'
import { useConfig, useEthers, useBlockNumber } from '@usedapp/core'
import { useMint, usePresaleMint, useContractState } from '../../hooks/daskhooks'
import { utils, BigNumber } from 'ethers'
import { Transactions } from '../notifications/Transactions'
import { MerkleTree } from 'merkletreejs'
import addresses from '../../addresses-clean.json'
import keccak256 from 'keccak256'

global.Buffer = global.Buffer || require('buffer').Buffer

const leaves = addresses.map(a => keccak256(a))
const tree = new MerkleTree(leaves, keccak256, { sortPairs: true })
const root = tree.getHexRoot()
console.log("root", root)

function Main() {

    const { activateBrowserWallet, account } = useEthers()
    const { networks } = useConfig()

    const blockNumber = useBlockNumber()
    const [proof, setProof] = useState()
    const [isEligable, setEligable] = useState(false)
    const [val, setVal] = useState(1)
    const [errorMessage, setErrorMessage] = useState()
    const { mint, mintState, mintEvents } = useMint()
    const { presaleMint, presaleMintState, presaleMintEvents } = usePresaleMint()

    const { price, MAX_PER_MINT, MAX_TOKENS, publicSaleStarted, presaleStarted, totalSupply } = useContractState()
    const isSoldOut = BigNumber.isBigNumber(totalSupply) && BigNumber.isBigNumber(MAX_TOKENS) && totalSupply.eq(MAX_TOKENS)

    useEffect(() => {
        window.ethereum && window.ethereum.on('chainChanged', (chainId) => {
            if (chainId !== '0x' + networks[0].chainId) {
                window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x" + networks[0].chainId }],
                })
            }
        })
    }, [networks])

    useEffect(() => {
        console.log("mintState", mintState)
        console.log("mintEvents", mintEvents)
        console.log("presaleMintState", presaleMintState)
        console.log("presaleMintEvents", presaleMintEvents)
    }, [mintState, mintEvents, presaleMintState, presaleMintEvents])

    useEffect(() => {
        const hexProof = tree.getHexProof(keccak256(account))
        setProof(hexProof)
        const verified = tree.verify(hexProof, keccak256(account), root)
        setEligable(verified)
        console.log("verified?", verified)
    }, [account])

    const onConnectWallet = async () => {
        setErrorMessage()
        activateBrowserWallet(async (er) => {
            if (er) {
                console.log(er)
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x" + networks[0].chainId }],
                })
                activateBrowserWallet()
            }
        }, false)
    }

    const [errComponent, setErrComponent] = useState("")

    const mintBtnClick = async () => {
        setErrorMessage()
        if (val && val > 0 && val <= 20) {
            await mint(val, { value: price.mul(val) })
                .catch((e) => {
                    console.log(e)
                    if (e) {
                        setErrorMessage(e.message)
                    }
                })
            setErrComponent("")

        } else {
            setErrComponent("Enter number between 1 and 20")
        }
    }

    const presaleMintBtnClick = async () => {
        setErrorMessage()
        if (val && val > 0 && val <= 20) {
            await presaleMint(val, proof, { value: price.mul(val) })
            setErrComponent("")
        } else {
            setErrComponent("Enter number between 1 and 20")
        }
    }

    return (
        <main className="bg-white sm:max-w-[380px] mx-auto flex rounded-xl py-6 px-4 my-8 drop-shadow flex-col items-center h-full">
            {!price && <div className="absolute z-10 w-full h-full backdrop-blur-sm"></div>}
            <div>
                <div className='rounded-xl' style={{ "display": "inline-block", "maxWidth": "100%", "overflow": "hidden", "position": "relative", "boxSizing": "border-box", "margin": "0" }}>
                    <video muted autoPlay={"autoplay"} loop>
                        <source src={vid} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            <h1 className="my-2 text-2xl font-bold">Join the KLUB</h1>
            <h2 className="my-1 text-lg font-bold text-pink-500">{BigNumber.isBigNumber(totalSupply) && totalSupply.toString()}/{BigNumber.isBigNumber(MAX_TOKENS) && MAX_TOKENS.toString()} AT {price && utils.formatEther(price)} EACH</h2>
            <div className="mb-0 text-base text-blue-400" style={{fontFamily:"RobotoMedium"}} >Max {BigNumber.isBigNumber(MAX_PER_MINT) && MAX_PER_MINT.toString()} per transaction</div>
            {account && presaleStarted && !publicSaleStarted && !isSoldOut && <div className="mb-0 text-base text-blue-400" style={{fontFamily:"RobotoMedium"}}>6 presale mint per wallet</div>}
            <form className="w-full">
                <div>
                    <div>
                        <label htmlFor="quant" className="text-lg block font-medium leading-10 text-center text-gray-500 overflow-ellipsis">Enter quantity:</label>
                        <input type="number" min="1" max="20" onFocus={(e) => e.target.select()} name="quantity" autoFocus value={val} onChange={(e) => { setVal(e.target.value) }} className="w-full py-2 mb-4 text-lg font-bold text-center text-blue-600 border outline-none" />
                        <h5 className="text-[red] text-center">{errComponent}</h5>
                    </div>
                </div>
                {!account &&
                    <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-yellow-500 rounded hover:bg-yellow-600 active:bg-yellow-500 disabled:bg-yellow-300 disabled:cursor-default" onClick={onConnectWallet}>Connect Wallet</button>}
                {account && !presaleStarted && !publicSaleStarted &&
                    <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-zinc-500 rounded hover:bg-zinc-600 active:bg-zinc-500 disabled:bg-zinc-300 disabled:cursor-default">Sale has not started yet</button>}
                {account && presaleStarted && !publicSaleStarted && !isSoldOut && !isEligable &&
                    <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-zinc-500 rounded hover:bg-zinc-600 active:bg-zinc-500 disabled:bg-zinc-300 disabled:cursor-default">Not in the Pre-sale White List</button>}
                {account && presaleStarted && !publicSaleStarted && !isSoldOut && isEligable &&
                    <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-indigo-500 rounded hover:bg-indigo-600 active:bg-indigo-500 disabled:bg-indigo-300 disabled:cursor-default" onClick={presaleMintBtnClick}>Presale Mint</button>}
                {account && publicSaleStarted && !isSoldOut &&
                    <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-blue-500 rounded hover:bg-blue-600 active:bg-blue-500 disabled:bg-blue-300 disabled:cursor-default" onClick={mintBtnClick}>Mint</button>}
                {account && isSoldOut &&
                    <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-rose-500-500 rounded hover:bg-rose-600 active:bg-rose-500 disabled:bg-rose-300 disabled:cursor-default">Sold Out!</button>}
            </form>
            <span className="mt-3 text-gray-500" style={{fontFamily:"RobotoMedium"}}>Current block: {blockNumber}</span>
            <div className='w-full mt-3 text-gray-500' style={{fontFamily:"RobotoMedium"}}>
                {errorMessage && <h5 className="text-pink-800">{errorMessage}</h5>}
                {publicSaleStarted && mintState.status !== "None" && <div>
                    <h5 className="text-center mx-3">Status: <span>{mintState.status}</span></h5>
                    <h5 className="text-[red] text-center w-full">{mintState.errorMessage}</h5>
                </div>}
                {presaleStarted && !publicSaleStarted && presaleMintState.status !== "None" && <div>
                    <h5 className="text-center mx-3">Status: <span>{presaleMintState.status}</span></h5>
                    <h5 className="text-[red] text-center w-full">{presaleMintState.errorMessage}</h5>
                </div>}
                <div className='w-full text-blue-400'>{<Transactions />}</div>
            </div>
        </main>
    )
}


export default Main;