import { useContractCalls, useContractFunction } from '@usedapp/core'
import { Contract } from '@ethersproject/contracts'
import { utils } from 'ethers'
import dask from '../DASK.json'

const abi = dask.abi
const daskInterface = new utils.Interface(abi)
const daskContractAddress = '0x19d84B2A4B21910339aF097A1bDdB48682D6F47d'
const contract = new Contract(daskContractAddress, daskInterface)

const call = (name, args) => {
    return {
        abi: daskInterface,
        address: daskContractAddress,
        method: name,
        args: args ?? [],
    }
}
const useContractState = () => {
    const [price, MAX_PER_MINT, MAX_TOKENS, isRevealed, publicSaleStarted, presaleStarted, totalSupply] =
        useContractCalls([
            call('price'),
            call('MAX_PER_MINT'),
            call('MAX_TOKENS'),
            call('isRevealed'),
            call('publicSaleStarted'),
            call('presaleStarted'),
            call('totalSupply'),
        ]
        ) ?? []
    return {
        price: price?.[0],
        MAX_PER_MINT: MAX_PER_MINT?.[0],
        MAX_TOKENS: MAX_TOKENS?.[0],
        isRevealed: isRevealed?.[0],
        publicSaleStarted: publicSaleStarted?.[0],
        presaleStarted: presaleStarted?.[0],
        totalSupply: totalSupply?.[0],
    }
}

const useMint = () => {
    const fn = useContractFunction(contract, 'mint')
    return { mint: fn.send, mintState: fn.state, mintEvents: fn.events }
}

const usePresaleMint = () => {
    const fn = useContractFunction(contract, 'mintPresale')
    return { presaleMint: fn.send, presaleMintState: fn.state, presaleMintEvents: fn.events }
}

export { useMint, usePresaleMint, useContractState }