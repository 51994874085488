import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChainId, DAppProvider } from '@usedapp/core'
import { Mainnet, Rinkeby } from '@usedapp/core'

const SupportedChain = Mainnet

const config = {
  networks: [SupportedChain],
  readOnlyChainId: SupportedChain.chainId,
  readOnlyUrls: {
    [ChainId.Mainnet]: 'https://eth-mainnet.alchemyapi.io/v2/j-laCoDEuVRQkXht0rsTRRhogpfwXG5t',
    [ChainId.Rinkeby]: 'https://rinkeby.infura.io/v3/4b72c849071a41dbaf5eee4355478564',
  },
  multicallAddresses: {
    [ChainId.Mainnet]: Mainnet.multicallAddress,
    [ChainId.Rinkeby]: Rinkeby.multicallAddress,
  }
}



ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
